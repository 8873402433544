// @flow

import React from "react";

import postStyles from "../../../../../../../../css/components/post.css";

// <iframe frameBorder={0} src={props.file.file.url}/>

const PDF = (props) => {
    return (
        <div className={postStyles.pdf}>

        </div>
    );
};

export default PDF;
