// @flow

export const RESET_FEED = "RESET_FEED";
export const UPDATE_PERIOD = "UPDATE_PERIOD";
export const UPDATE_TYPE = "UPDATE_TYPE";
export const UPDATE_SORT = "UPDATE_SORT";
export const UPDATE_POSTS = "UPDATE_POSTS";
export const CREATE_ERROR = "CREATE_ERROR";
export const STOP_LOADING = "STOP_LOADING";
export const START_LOADING = "START_LOADING";
export const UPDATE_OFFSET = "UPDATE_OFFSET";
export const HIT_LIMIT = "HIT_LIMIT";
export const UPDATE_PREVIEW_SHOW = "UPDATE_PREVIEW_SHOW";
export const UPDATE_PREVIEW_USER_ID = "UPDATE_PREVIEW_USER_ID";
export const UPDATE_PREVIEW_USER = "UPDATE_PREVIEW_USER";
export const UPDATE_PREVIEW_POSTS = "UPDATE_PREVIEW_POSTS";
